import { utcYear } from "d3-time";
export var pathMonth = function (t, weekday, cellSize, timeWeek, countDay) {
    var n = weekday === "weekday" ? 5 : 7;
    var d = Math.max(0, Math.min(n, countDay(t.getUTCDay())));
    var w = timeWeek.count(utcYear(t), t);
    return (d === 0
        ? "M" + w * cellSize + ",0"
        : d === n
            ? "M" + (w + 1) * cellSize + ",0"
            : "M" + (w + 1) * cellSize + ",0V" + d * cellSize + "H" + w * cellSize) + "V" + n * cellSize;
};
export var sameDay = function (first, second) {
    return first.getUTCFullYear() === second.getUTCFullYear() &&
        first.getUTCMonth() === second.getUTCMonth() &&
        first.getUTCDate() === second.getUTCDate();
};
export var convertDateToUTCDate = function (date) {
    var dateUTC = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
    return new Date(dateUTC);
};
