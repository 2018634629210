import * as React from "react";
var defaultValues = {
    showTooltip: function () {
        throw new Error("TooltipContext not initalized");
    },
    hideTooltip: function () {
        throw new Error("TooltipContext not initalized");
    },
    disableTooltip: false,
    valueFn: function (n) { return "" + n; },
};
var TooltipContext = React.createContext(defaultValues);
export default TooltipContext;
