import "./Tooltip.css";
import * as React from "react";
import useTooltip from "./useTooltip";
export var Tooltip = React.memo(function (_a) {
    var label = _a.label, value = _a.value, projects = _a.projects;
    var _b = useTooltip(), tooltipClassName = _b.tooltipClassName, valueFn = _b.valueFn;
    return (React.createElement("div", { className: tooltipClassName },
        React.createElement("div", { className: "CalendarHeatMap__tooltip" },
            value !== undefined ? (React.createElement(React.Fragment, null,
                React.createElement("span", { className: "CalendarHeatMap__tooltipLabel" },
                    label,
                    ": "),
                React.createElement("span", { className: "CalendarHeatMap__tooltipValue" }, "" + valueFn(value)))) : (label),
            projects && (React.createElement("div", { className: "CalendarHeatMap__tooltipProjects" }, Object.keys(projects).map(function (project, index) {
                return (React.createElement("div", { key: index },
                    React.createElement("span", { className: "CalendarHeatMap__tooltipLabel" },
                        project,
                        ":",
                        " "),
                    React.createElement("span", { className: "CalendarHeatMap__tooltipValue" }, "" + valueFn(projects[project]))));
            }))))));
});
