import "./CalendarHeatMap.css";
import * as React from "react";
import classnames from "classnames";
import { utcYear, utcMonday, utcMonths, utcMonth, timeDay } from "d3-time";
import { scaleSequential } from "d3-scale";
import { interpolatePiYG } from "d3-scale-chromatic";
import { quantile, range } from "d3-array";
import { format } from "d3-format";
import { utcFormat } from "d3-time-format";
import TooltipProvider from "../Tooltip/TooltipProvider";
import Cell from "../Cell";
import { sameDay } from "../../helpers";
var CalendarHeatMap = function (_a) {
    var className = _a.className, data = _a.data, _b = _a.weekday, weekday = _b === void 0 ? "weekday" : _b, _c = _a.disableTooltip, disableTooltip = _c === void 0 ? false : _c, _d = _a.tooltipOffsetX, tooltipOffsetX = _d === void 0 ? 0 : _d, _e = _a.tooltipOffsetY, tooltipOffsetY = _e === void 0 ? 0 : _e, tooltipPlacement = _a.tooltipPlacement, tooltipClassName = _a.tooltipClassName, timeRange = _a.timeRange, _f = _a.customD3ColorScale, customD3ColorScale = _f === void 0 ? scaleSequential(interpolatePiYG) : _f, _g = _a.width, width = _g === void 0 ? 900 : _g, _h = _a.cellSize, cellSize = _h === void 0 ? 17 : _h, _j = _a.cellShape, cellShape = _j === void 0 ? "circle" : _j, _k = _a.formatDate, formatDate = _k === void 0 ? utcFormat("%Y-%m-%d") : _k, _l = _a.valueFn, valueFn = _l === void 0 ? format(".2f") : _l, _m = _a.defaultColor, defaultColor = _m === void 0 ? "#eeeeee" : _m, _o = _a.marginTop, marginTop = _o === void 0 ? 0 : _o, _p = _a.marginBottom, marginBottom = _p === void 0 ? 0 : _p, _q = _a.marginLeft, marginLeft = _q === void 0 ? 0 : _q, _r = _a.paddingUnderMonthHeader, paddingUnderMonthHeader = _r === void 0 ? 8 : _r, _s = _a.paddingAfterDayOfWeekHeader, paddingAfterDayOfWeekHeader = _s === void 0 ? 8 : _s, _t = _a.cellPadding, cellPadding = _t === void 0 ? 2 : _t, _u = _a.formatDay, formatDay = _u === void 0 ? function (dayOfWeek) { return "SMTWTFS"[dayOfWeek]; } : _u, _v = _a.fillToWidth, fillToWidth = _v === void 0 ? false : _v, _w = _a.labelsFillColor, labelsFillColor = _w === void 0 ? "black" : _w;
    var svgWidth = width;
    var _x = data, firstData = _x[0], _y = data.length - 1, lastData = _x[_y];
    var timeWeek = utcMonday;
    var currentTimeRange = React.useMemo(function () {
        return timeRange
            ? timeRange
            : {
                from: utcYear(new Date(firstData ? firstData.day : "")),
                to: new Date(lastData ? lastData.day : ""),
            };
    }, [timeRange, lastData, firstData]);
    var to = currentTimeRange.to;
    var from = currentTimeRange.from;
    if (fillToWidth) {
        var offsetMultipleYearsPlusPadding = 49;
        var offsetPosX = 28 + marginLeft + offsetMultipleYearsPlusPadding;
        var date = to;
        var maxPosX = void 0;
        do {
            maxPosX =
                timeWeek.count(timeWeek.ceil(date), to) * cellSize + 2 + offsetPosX;
            date = timeDay.offset(date, -1);
        } while (svgWidth >= maxPosX);
        from = date;
    }
    var timeRangeData = timeDay.range(from, to).map(function (day) {
        var utcDay = new Date(Date.UTC(day.getFullYear(), day.getMonth(), day.getDate()));
        var currentData = data
            .filter(function (item) { return sameDay(new Date(item.day), utcDay); })
            .pop();
        return currentData
            ? currentData
            : {
                day: utcDay.toISOString().slice(0, 10),
                value: 0,
            };
    });
    var formatMonth = utcFormat("%b");
    var max = quantile(timeRangeData, 0.9975, function (d) { return Math.abs(d.value); });
    var color = customD3ColorScale.domain([-max, +max]);
    var countDay = weekday === "weekend"
        ? function (i) { return (i - (1 % 7) + 7) % 7; }
        : function (i) { return (i + 6) % 7; };
    var rows = weekday === "weekday" ? range(1, 6) : range(0, 7);
    var columns = utcMonths(utcMonth(from), to);
    var cells = weekday === "weekday"
        ? timeRangeData.filter(function (d) { return ![0, 6].includes(new Date(d.day).getUTCDay()); })
        : timeRangeData;
    var svgHeight = cellSize * (weekday === "weekday" ? 6 : 8) +
        marginTop +
        marginBottom +
        paddingUnderMonthHeader;
    var isSameYear = from.getUTCFullYear() === to.getUTCFullYear();
    var offsetYear = isSameYear
        ? paddingAfterDayOfWeekHeader
        : paddingAfterDayOfWeekHeader + 15;
    var year = "" + from.getUTCFullYear() + (!isSameYear ? "/" + to.getUTCFullYear().toString().slice(-2) : "");
    var cellsNodes = React.useMemo(function () {
        return cells.map(function (c, index) {
            return (React.createElement(Cell, { key: index, c: c, color: color, cellSize: cellSize, countDay: countDay, timeWeek: timeWeek, formatDate: formatDate, from: from, cellShape: cellShape, defaultColor: defaultColor, cellPadding: cellPadding }));
        });
    }, [
        cellSize,
        cellShape,
        cellPadding,
        defaultColor,
        from,
        countDay,
        timeWeek,
        color,
    ]);
    return (React.createElement(TooltipProvider, { tooltipPlacement: tooltipPlacement, tooltipClassName: tooltipClassName, disableTooltip: disableTooltip, tooltipOffsetX: tooltipOffsetX, tooltipOffsetY: tooltipOffsetY, valueFn: valueFn },
        React.createElement("div", { className: classnames("CalendarHeatMap", className) },
            React.createElement("svg", { fontSize: "10px", viewBox: "0 0 " + svgWidth + " " + svgHeight },
                React.createElement("g", { transform: "translate(" + (28 + marginLeft + offsetYear) + ", " + (10 + marginTop + paddingUnderMonthHeader) + ")" },
                    React.createElement("text", { x: -paddingAfterDayOfWeekHeader, y: -paddingUnderMonthHeader, fontWeight: "bold", textAnchor: "end", fill: labelsFillColor }, year),
                    React.createElement("g", { textAnchor: "end" }, rows.map(function (row, index) {
                        return (React.createElement("text", { key: index, x: -paddingAfterDayOfWeekHeader, y: (countDay(row) + 0.5) * cellSize, dy: "0.31em", fill: labelsFillColor }, formatDay(row)));
                    })),
                    React.createElement("g", null, cellsNodes),
                    React.createElement("g", null, columns.map(function (d, index) {
                        var monthPosX = timeWeek.count(from, timeWeek.ceil(d)) * cellSize + 2;
                        if (monthPosX <= -10) {
                            return null;
                        }
                        return (React.createElement("g", { key: index },
                            React.createElement("text", { x: Math.max(monthPosX, 0), y: -paddingUnderMonthHeader, fill: labelsFillColor }, formatMonth(d))));
                    })))))));
};
export default CalendarHeatMap;
