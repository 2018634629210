import * as React from "react";
import { useMeasure } from "react-use";
export var TooltipContainer = React.memo(function (_a) {
    var position = _a.position, placement = _a.placement, _b = _a.offsetX, offsetX = _b === void 0 ? 0 : _b, _c = _a.offsetY, offsetY = _c === void 0 ? 0 : _c, children = _a.children;
    var _d = useMeasure(), measureRef = _d[0], _e = _d[1], width = _e.width, height = _e.height;
    var newX = position[0], newY = position[1];
    var x = Math.round(newX);
    var y = Math.round(newY);
    if (width > 0 && height > 0) {
        switch (placement) {
            case "bottom":
                x -= width / 2;
                break;
            case "bottomLeft":
                x -= width;
                y += height / 2;
                break;
            case "bottomRight":
                x += 25;
                y += 20;
                break;
            case "left":
                x -= width + offsetX;
                y -= height / 2 + offsetY;
                break;
            case "right":
                x += 20 + offsetX;
                y -= height / 2 + offsetY;
                break;
            case "top":
                x -= width / 2;
                y -= height;
                break;
            case "topLeft":
                x -= width;
                y -= height;
                break;
            case "topRight":
                x += 20;
                y -= height;
                break;
            default:
                break;
        }
    }
    var style = {
        pointerEvents: "none",
        position: "absolute",
        zIndex: 10,
        top: 0,
        left: 0,
        maxWidth: "calc(50% - 15px)",
        transform: "translate(" + x + "px, " + y + "px)",
    };
    return (React.createElement("div", { ref: measureRef, style: style }, children));
});
export default TooltipContainer;
