var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import useTooltip from "../Tooltip/useTooltip";
import { Tooltip } from "../Tooltip/Tooltip";
var Cell = function (_a) {
    var color = _a.color, cellSize = _a.cellSize, cellPadding = _a.cellPadding, c = _a.c, countDay = _a.countDay, timeWeek = _a.timeWeek, formatDate = _a.formatDate, from = _a.from, cellShape = _a.cellShape, defaultColor = _a.defaultColor, rest = __rest(_a, ["color", "cellSize", "cellPadding", "c", "countDay", "timeWeek", "formatDate", "from", "cellShape", "defaultColor"]);
    var _b = useTooltip(), hideTooltip = _b.hideTooltip, showTooltip = _b.showTooltip, disableTooltip = _b.disableTooltip;
    var tooltipComponent = (React.createElement(Tooltip, { key: c.day, label: "" + formatDate(new Date(c.day)), value: c.value, projects: "projects" in c ? c["projects"] : undefined }));
    var handleMouseMove = React.useCallback(function (ev) {
        showTooltip(tooltipComponent, ev);
    }, [showTooltip, from]);
    var handleMouseLeave = React.useCallback(function () {
        hideTooltip();
    }, [hideTooltip]);
    var cellDay = new Date(c.day);
    var x = timeWeek.count(from, cellDay) * cellSize + cellPadding / 2;
    var y = countDay(cellDay.getUTCDay()) * cellSize + cellPadding / 2;
    return (React.createElement("rect", __assign({ onMouseEnter: disableTooltip ? undefined : handleMouseMove, onMouseLeave: disableTooltip ? undefined : handleMouseLeave, onMouseMove: disableTooltip ? undefined : handleMouseMove, rx: cellShape === "circle" ? 9999 : 1, width: cellSize - cellPadding, height: cellSize - cellPadding, x: x, y: y, fill: c.value ? color(c.value) : defaultColor }, rest)));
};
export default Cell;
